<script lang="ts" setup>
const { categories } = await useCategories()
const { getThumbnail } = useDirectusFiles()

const hoveredCategoryIndex = ref()

const firstRowIndexes = [0, 1, 2]
const lastRowIndexes = [3, 4, 5]
</script>

<template>
  <div flex flex-col lg:flex="row wrap" bg-white gap-y-2px lg:gap-0 w-full>
    <NuxtLink v-for="(category, index) in categories" :key="category.title!" :to="`/kategorie/${category.slug!}`" flex-item relative w-full cursor-pointer transition-all linear duration-500 :class="[hoveredCategoryIndex === undefined ? 'lg:max-w-1/3' : hoveredCategoryIndex === index ? 'lg:max-w-5/13' : (firstRowIndexes.includes(hoveredCategoryIndex) && firstRowIndexes.includes(index)) || (lastRowIndexes.includes(hoveredCategoryIndex) && lastRowIndexes.includes(index)) ? 'lg:max-w-4/13' : 'lg:max-w-1/3']" @mouseenter="hoveredCategoryIndex = index" @mouseleave="hoveredCategoryIndex = undefined">
      <NuxtImg :src="getThumbnail(category.img, { format: 'webp' })" placeholder="/images/shimmer-placeholder.gif" w-full brightness-50 hover:brightness-80 lg:brightness-100 object-cover lg:max-h-243px lg:h-243px xl:max-h-292px xl:h-292px class="2xl:max-h-343px 2xl:h-343px" h-full transition-all linear duration-500 />
      <div absolute inset-0 bg-white opacity-80 hidden lg:block hover:opacity-40 hover:bg-black transition-all linear duration-500 />
      <p absolute top="50%" left="50%" translate-x="-50%" translate-y="-50%" text="24px lg:32px" leading-40px text-white lg:text-app-text-gray-dark underline lg:no-underline font-extrabold class="disable-hover" :class="{ 'lg:text-white! lg:underline!': hoveredCategoryIndex === index }" whitespace-nowrap transition-all linear duration-500>
        {{ category.title }}
      </p>
    </NuxtLink>
  </div>
</template>

<style>
.disable-hover {
  pointer-events: none;
}
</style>
